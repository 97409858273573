import React from 'react'

const videoClasses = `
  w-full
  block
  align-bottom
`

const captionClasses = `
  mt-2
  text-center
  text-base
  font-sans
  font-bold
  leading-tight
`

const ContentVideo = ({ sources, caption, youTubeID, embedHtml, poster }) => (
  <div>
    { (embedHtml && !sources) &&
      <div className="intrinsic-video" dangerouslySetInnerHTML={{ __html: embedHtml }}>
      </div>
    }
    { (youTubeID && !sources) &&
      <div className="intrinsic-video">
        <iframe src={ `http://www.youtube.com/embed/${ youTubeID }` } frameborder="0" allowfullscreen></iframe>
      </div>
    }
    { (!youTubeID && sources) &&
      <video className={ videoClasses } controls poster={poster || null}>
        { sources.map((source, i) =>
          <source key={ 'video' + i } src={ source.url } type={ `video/${ source.type }` } />
        )}
      </video>
    }
    { caption ? (
      <div className={ captionClasses }>{ caption }</div>
    ) : ('')}
  </div>
)

export default ContentVideo
