import React from 'react'
import cx from 'classnames'
import ContentVideo from '../ContentVideo'

const imageClasses = `
  inline-block
  align-bottom
`

const captionClasses = `
  mt-2
  text-base
  font-sans
  font-bold
  leading-tight
`

const ContentImage = ({
  img,
  caption,
  isVideo,
  videoUrl,
  alignment = 'full'
}) => (
  <div>
    { !isVideo ? (
      <div className={cx({
        'text-left': alignment === 'left',
        'text-right': alignment === 'right',
        'text-center': alignment === 'center' || alignment === 'full'
      })}
      >
        <div
          className="inline-block"
          style={{
            maxWidth: img.width
          }}
        >
          <img
            className={cx(imageClasses, {
              'w-full': alignment === 'full'
            })}
            src={ img.src }
            alt={ img.alt }
          />
          { caption ? (
            <div className={ captionClasses }>{ caption }</div>
          ) : ('')}
        </div>
      </div>
    ) : (
      <ContentVideo sources={[{ url: videoUrl, type: 'mp4' }]} poster={(img && img.src) || null}/>
    )}
  </div>
)

export default ContentImage
