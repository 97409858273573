import React from 'react'
import debounce from '../../../utils/debounce'

const createFrame = () => {
  const frame = document.createElement('iframe')
  frame.width = '100%'
  frame.height = '100%'
  frame.frameBorder = 0
  frame.scrolling = 'no'
  frame.src = 'about:blank'

  return frame
}

const createFrameMarkup = embed => (
  `<!DOCTYPE html>
   <html>
    <head>
      <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no, shrink-to-fit=no">
      <style>
        body {margin: 0;}
        iframe { width: 100vw !important; }
      </style>
    </head>
    <body>
      ${ embed }
    </body>
  </html>`
)

class ScriptEmbed extends React.PureComponent {
  constructor (props) {
    super(props)
    this.containerRef = React.createRef()
    this.resizeHandler = debounce(this.updateFrameHeight, 250)
  }

  updateFrameHeight = () => {
    this.frame.height = this.frame.contentDocument.body.scrollHeight
  }

  componentDidMount () {
    if (!this.props.embed) return

    this.frame = createFrame()
    this.frame.onload = () => {
      // watch for changes to iframe DOM and update height
      // TODO: should also listen for browser resize to update iframe height
      this.observer = new window.MutationObserver(this.updateFrameHeight)

      this.observer.observe(this.frame.contentDocument.body, {
        childList: true,
        attributes: true,
        subtree: true
      })

      // Update the frame height initially to handle any content that is in
      // iframe by the time onload callback is invoked
      this.updateFrameHeight()
    }

    // listen for resize events so we can update frame height
    window.addEventListener('resize', this.resizeHandler)

    // append our iframe to the render tree
    this.containerRef.current.appendChild(this.frame)

    /**
     * Support for browsers that don't support srcdoc. The reason this
     * can't be used for all browsers is Safari will not send referrer header
     * from requests originating in iframe when not using srcdoc which some
     * embeds rely on.
     */
    if (!('srcdoc' in this.frame)) {
      this.frame.contentWindow.document.open('text/html', 'replace')
      this.frame.contentWindow.document.write(
        createFrameMarkup(this.props.embed)
      )
      this.frame.contentWindow.document.close()
    } else {
      this.frame.srcdoc = createFrameMarkup(this.props.embed)
    }
  }

  componentWillUnmount () {
    this.observer.disconnect()
    window.removeEventListener('resize', this.resizeHandler)
  }

  render () {
    return this.props.embed ? <div ref={this.containerRef} /> : null
  }
}

export default ScriptEmbed
