import React from 'react'
import { graphql } from 'gatsby'
import { extract } from '../../../utils/graphql'
import { localizePath } from '../../../../config/i18n'

// components
import EmptyPage from '../../../components/Templates/EmptyPage'
import NoTranslation from '../../../components/Templates/NoTranslation'

// dynamic components
import WysiwygContent from '../../../components/General/WysiwygContent'
import ContenImage from '../../../components/General/ContentImage'
import BlockQuote from '../../../components/General/Blockquote'
import ScriptEmbed from '../../../components/General/ScriptEmbed'
import { withPreview } from 'gatsby-source-prismic'

const transformSlicesData = function (slice) {
  let data = {
    type: slice.slice_type
  }

  switch (slice.slice_type) {
  case 'wysiwyg_field':
    data.content = slice.primary.content.html
    break
  case 'content_image':
    data.img = {
      src: slice.primary.image.url,
      alt: slice.primary.image.alt
    }
    data.caption = slice.primary.caption.text
    break
  case 'block_qoute':
    data.text = slice.primary.qoute.text
    break
  case 'script_embed':
    data.embed = slice.primary.script_embed
  }
  return data
}

const Security = ({ data, pageContext }) => {
  const { prismicSecurity } = data
  const page = prismicSecurity
  if (!page) return (<NoTranslation></NoTranslation>)
  const hydratedData = {
    lang: pageContext.locale,
    seo: extract.seo(page),
    mantelData: extract.mantel(page, {
      isThick: true,
      parent: {
        path: localizePath(pageContext.locale, `/resources`),
        text: 'Resources'
      }
    }),
    content: page.data.body.map(item => transformSlicesData(item))
  }

  return (
    <EmptyPage { ...hydratedData } >
      <div className="max-w-xl mx-auto break-words">
        { hydratedData.content.map((section, idx) => {
          if (section.type === 'wysiwyg_field') {
            return (<div key={ 'section' + idx } className='my-4'><WysiwygContent { ...section } /></div>)
          }
          if (section.type === 'content_image') {
            return (<div key={ 'section' + idx } className='my-4'><ContenImage { ...section } /></div>)
          }
          if (section.type === 'block_qoute') {
            return (<div key={ 'section' + idx } className='my-4'><BlockQuote>{ section.text }</BlockQuote></div>)
          }
          if (section.type === 'script_embed') {
            return (<div key={ 'section' + idx } className='my-4'><ScriptEmbed { ...section }></ScriptEmbed></div>)
          }
        }) }
      </div>
    </EmptyPage>
  )
}

export default withPreview(Security)

export const pageQuery = graphql`
  query SecurityByLocale( $locale: String! ) {
    prismicSecurity( lang: { eq: $locale } ) {
      uid
      data {
        title {
          text
        }
        seo_keywords {
          keyword {
            text
          }
        }
        seo_description {
          text
        }
        mantel_title {
          text
        }
        body {
          ... on PrismicSecurityBodyWysiwygField {
            slice_type
            primary {
              content {
                html
              }
            }
          }
          ... on PrismicSecurityBodyScriptEmbed {
            slice_type
            primary {
              script_embed
            }
          }
        }
      }
    }
  }
`
