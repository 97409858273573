import React from 'react'

// components
import PageWrap from '../../Layouts/Global/PageWrap'
import Mantel from '../../General/Mantel'

const Faq = ({ seo, mantelData, bg, children }) => (
  <PageWrap bg={bg} seo={seo}>
    <Mantel {...mantelData} />
    <div className="max-w-xl mx-auto pb-8">{children}</div>
  </PageWrap>
)

export default Faq
