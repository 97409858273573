import React from 'react'

const blockquoteClasses = `
  pl-6
  py-1
  border-l-2
  border-magenta
  font-sans
  font-bold
  text-3xl
`

const Blockquote = ({ children }) => (
  <blockquote className={ blockquoteClasses }>{ children }</blockquote>
)

export default Blockquote
